@if (isBrowserNotificationDisabled$ | async) {
  <div class="o8-error-message warning-banner">
    <div class="container">
      <o8-icon [icon]="warningIcon"></o8-icon>
      <p>
        <strong>Warning:</strong> your browser settings are currently not allowing notifications from this website, which
        may prevent it from functioning. Please allow notifications in your browser. For
        details, refer to the
        <a href="https://docs.google.com/document/d/1LIvPh2pkn1aZ8GLtt54SOadDlfyq9TnA9_OGvrmSBhM" target="_blank"
          >help documentation</a
          >.
        </p>
      </div>
    </div>
  }
